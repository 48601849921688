import { render, staticRenderFns } from "./Conversation.vue?vue&type=template&id=641d985e&scoped=true"
import script from "./Conversation.vue?vue&type=script&lang=js"
export * from "./Conversation.vue?vue&type=script&lang=js"
import style0 from "./Conversation.vue?vue&type=style&index=0&id=641d985e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641d985e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VIcon,VRow,VTextarea})
