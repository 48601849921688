/* eslint-disable no-unused-vars */
import { Auth } from "aws-amplify";
import { messageType, OutgoingMessage } from "@/plugins/zentinel";
import uuid from "uuid";
import profileApi from "@/services/apiCalls/profile/profile";
import apiBuyer from "@/services/apiCalls/admin/buyers";
import ErrorParser from "@/utils/ErrorParser";
import app_config from "@/app_config";

export default {
  data() {
    return {
      areFilesUploading: false,
      encryptionDetails: null,
    };
  },

  computed: {
    pofDocument() {
      return this.$store.getters.pofDocument;
    },
  },

  methods: {
    removeFile(index) {
      const payload = this.pofDocument.slice(1, index);
      // eslint-disable-next-line no-console
      console.log("removeFile payload ", payload);
      this.updateBuyerPoFBlock(payload);
    },
    removeFileByKey(file) {
      // const payload = this.pofDocument.splice(index, 1);
      const payload = this.pofDocument.filter((doc) => {
        return doc.key !== file.key;
      });

      this.updateBuyerPoFBlock(payload);
    },
    removeFileByIndex(index) {
      const payload = this.pofDocument.filter((doc, i) => {
        return i !== index;
      });
      this.updateBuyerPoFBlock(payload);
    },

    async getEncryptionDetails() {},

    // eslint-disable-next-line no-unused-vars
    async upload(fileToUpload) {
      this.areFilesUploading = true;
      try {
        const indexOffset = this.pofDocument.length || 0;
        const response = await this.$zentinel.uploadFile(fileToUpload.data);
        const file = {
          name: fileToUpload.name || fileToUpload.file.name,
          key: response.result,
          type: "POF",
          dataOwnerId: response.dataOwnerId,
        };
        await this.updateBuyerPoFBlock([file]);
        await this.createBuyerRequest();
      } catch (e) {
        console.error(e);
        if (e.message === "Listener timed out") {
          e.message =
            "The Zentinel encryption service is experiencing an issue. Please try to upload files later.";
        }
        if (e?.error?.message === "Timeout error") {
          this.$store.commit("openSnackBar", {
            text: "Your internet connection is too slow or your file size is too big. Please try again later.",
            color: "error",
          });
        } else {
          this.$store.commit("openSnackBar", {
            text: e?.error?.message,
            color: "error",
          });
        }
      } finally {
        this.areFilesUploading = false;
      }
    },

    onSelectFile(e) {
      const input = this.$refs.fileInput;

      if (input.files && input.files[0]) {
        const file = input.files[0];
        if (file.size > this.$zentinel.MAX_FILE_SIZE) {
          this.$store.commit("openSnackBar", {
            text: "File size must not exceed 5MB",
            color: "error",
          });
          return;
        }
        const reader = new FileReader();
        reader.onload = (onloadEvent) => {
          this.upload({
            data: onloadEvent.target.result,
            id: uuid.v4(),
            file,
          });
        };
        reader.readAsDataURL(file);
      }
      e.target.value = null;
    },

    async updateBuyerPoFBlock(payload) {
      // eslint-disable-next-line no-console
      console.log("payload", payload);
      this.areFilesUploading = true;
      try {
        const { data } = await profileApi.updateBuyerPoFBlock(payload);
        // eslint-disable-next-line no-console
        console.log("updateBuyerPoFBlock data", data);
        this.$store.commit("setUser", {
          user: data.updateBuyerPoFBlock,
        });
      } catch (e) {
        console.error(e);
        this.$store.commit("openSnackBar", {
          text: ErrorParser(e),
          color: "error",
        });
      } finally {
        this.areFilesUploading = false;
      }
    },

    async createBuyerRequest() {
      try {
        this.$store.commit("spinner", true);

        const documents = this.pofDocument.map((doc) => ({
          name: doc.name,
          key: doc.key,
          type: doc.type,
          dataOwnerId: doc.dataOwnerId,
        }));

        try {
          // eslint-disable-next-line no-console
          console.log("createBuyerRequest", documents);
          await apiBuyer.createBuyerRequest(documents);
          this.$store.commit("setIsBuyerUnderReview", true);
        } catch (e) {
          this.handleLogs({
            errorInfo: JSON.stringify(e),
            errorId: "Creating Buyer Request",
          });
          this.$store.commit("openSnackBar", {
            text: ErrorParser(e),
            color: "error",
          });
        }

        this.$store.commit("openSnackBar", {
          text: "Your request has been sent successfully.",
          color: "info",
        });
      } catch (err) {
        console.error(err, "err");
        this.$store.commit("openSnackBar", {
          text: ErrorParser(err),
          color: "error",
        });
      } finally {
        this.$store.commit("spinner", false);
      }
    },
  },
};
