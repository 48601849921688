import { API } from "aws-amplify";
import countBuyerOffers from "./queries/countBuyerOffers";
import { OFFER } from "@/services/apiCalls/types/Offer/Offer";
import { DEPOSIT } from "@/services/apiCalls/types/Offer/Deposit";
import { DEPOSIT_HISTORY } from "@/services/apiCalls/types/Offer/DepositHistory";
import { DOCUMENT_FOR_SIGN } from "@/services/apiCalls/types/Offer/DocumentForSign";

export default {
  countBuyerOffers,
  createOffer({
    assets,
    bidPrice,
    closing,
    dueDiligencePeriod,
    earnestMoneyDeposit,
    notes,
    type,
  }) {
    const mutation = `mutation createOffer (
            $assets: [OfferAssetInput]!,
            $bidPrice: ValueAmountInput!,
            $closing: Int!,
            $dueDiligencePeriod: Int!,
            $earnestMoneyDeposit: ValueAmountInput!,
            $notes: String,
            $type: OfferType!
        ) {
            createOffer(
            assets: $assets,
		        bidPrice: $bidPrice,
		        closing: $closing,
		        dueDiligencePeriod: $dueDiligencePeriod,
		        earnestMoneyDeposit: $earnestMoneyDeposit,
		        notes: $notes,
		        type: $type,
            ) {
              ${OFFER}
            }
        }`;

    const variables = {
      assets,
      bidPrice: {
        amount: bidPrice,
      },
      closing,
      dueDiligencePeriod,
      earnestMoneyDeposit: {
        amount: earnestMoneyDeposit,
      },
      notes,
      type,
    };

    return API.graphql({
      query: mutation,
      variables,
    });
  },
  getTier2RequestForUser(variables) {
    const query = `query getTier2RequestForUser ($userId: String, $assetId: String){
      getTier2RequestForUser(userId:$userId , assetId :$assetId ){
        requests{
          id
          status
          dateModified
        }
       
      }

  }`;

    return API.graphql({
      query,
      variables,
    });
  },
  editOffer({
    id,
    bidPrice,
    closing,
    dueDiligencePeriod,
    earnestMoneyDeposit,
    notes,
    assets,
    buyerClosingDocumentsAccepted,
    sellerClosingDocumentsAccepted,
  }) {
    const mutation = `mutation editOffer (
            $id: String!,
            $bidPrice: ValueAmountInput,
            $closing: Int,
            $dueDiligencePeriod: Int,
            $earnestMoneyDeposit: ValueAmountInput,
            $notes: String,
            $assets: [OfferAssetEdit]
            $buyerClosingDocumentsAccepted: Boolean
            $sellerClosingDocumentsAccepted: Boolean
        ) {
            editOffer(
            id: $id
		        bidPrice: $bidPrice,
		        closing: $closing,
		        dueDiligencePeriod: $dueDiligencePeriod,
		        earnestMoneyDeposit: $earnestMoneyDeposit,
            notes: $notes,
            assets: $assets,
            buyerClosingDocumentsAccepted: $buyerClosingDocumentsAccepted,
            sellerClosingDocumentsAccepted: $sellerClosingDocumentsAccepted,
            ) {
              ${OFFER}
            }
        }`;

    const variables = {
      id,
      bidPrice: {
        amount: bidPrice,
      },
      closing,
      dueDiligencePeriod,
      earnestMoneyDeposit: {
        amount: earnestMoneyDeposit,
      },
      notes,
      assets: assets
        ? assets
        : [
          {
            bidAllocation: {
              amount: bidPrice,
            },
          },
        ],
      buyerClosingDocumentsAccepted,
      sellerClosingDocumentsAccepted,
    };

    return API.graphql({
      query: mutation,
      variables,
    });
  },

  editOfferSidePanelSeller(variables) {
    const mutation = `mutation editOffer (
            $id: String!,
            $bidPrice: ValueAmountInput,
            $closing: Int,
            $dueDiligencePeriod: Int,
            $earnestMoneyDeposit: ValueAmountInput,
            $notes: String,
            $assets: [OfferAssetEdit]
        ) {
            editOffer(
                id: $id
		        bidPrice: $bidPrice,
		        closing: $closing,
		        dueDiligencePeriod: $dueDiligencePeriod,
		        earnestMoneyDeposit: $earnestMoneyDeposit,
                notes: $notes,
                assets: $assets
            ) {
              ${OFFER}
            }
        }`;

    return API.graphql({
      query: mutation,
      variables,
    });
  },
  publishOffer(id) {
    const mutation = `mutation($id: String!) {
            publishOffer(id: $id) {
                ${OFFER}
            }
        }`;

    return API.graphql({
      query: mutation,
      variables: {
        id,
      },
    });
  },
  withdrawOffer(id) {
    const mutation = `mutation withdraw($id: String!) {
        withdrawOffer(id: $id) {
          status
        }
      }`;
    return API.graphql({
      query: mutation,
      variables: {
        id,
      },
    });
  },
  listOffers() {
    const query =
      "listOffers(limit: Int, nextToken: String, filter: OfferFilter)";

    return API.graphql({
      query,
      variables: {},
    });
  },
  searchOffersByUser(
    id,
    statuses,
    offerType,
    offset = 0,
    size = 10,
    address,
    sortBy,
    orderBy
  ) {
    const query = `query searchOffersByUser($id: String, $status: [String], $offerType: [String], $size: Int $offset: Int, $sortBy: ESOffersSortingBy, $orderBy: OrderByChoices, $address: String){
      searchOffersByUser(id: $id, status: $status, offerType: $offerType, size: $size, offset: $offset, sortBy: $sortBy, orderBy: $orderBy, address: $address) {
        offers {
          depositHistory {
            ${DEPOSIT_HISTORY}
          }
          id
          buyerClosingPeriod {
            startDate
            endDate
          }
          BDASignedDate
          declineReason
          SSDocument{
            ${DOCUMENT_FOR_SIGN}
          }
          BDApdfKey
          owners
          
          buyerClosingDocumentsAccepted
              deposit{
               value{
                 amount
                currency
               }
              }
          assets {
            id
            title
            currentPeriodEndDate
            contactsDisclosed
            contacts {
              firstName
              lastName
              officePhone
              emailAddress
              companyName
              info {
                avatar
                jobTitle
              }
              whatsappNumber
              contactAvatar
            }
            noteOverview {
              unpaidPrincipalBalance {
                amount
              }
            }
            principalBalance {
              amount
            }
            bidAllocation {
              amount
            }
            callForOffersPeriod {
              startDate
              endDate
            }
            bestAndFinalPeriod {
              startDate
              endDate
            }
            address {
              address
              city
              state
              country
            }
            name
            images
            status
            type
            assetStatus
          }
          bidPrice
          closing
          dateModified
          dueDiligencePeriod
          earnestMoneyDeposit
          notes
          status
          offerType
          creationDate
          acceptedDate
          winningDate
          declinedDate
          withdrawDate
        }
        total
      }
    }`;

    return API.graphql({
      query,
      variables: {
        id,
        address,
        status: statuses,
        offerType,
        offset,
        size,
        sortBy,
        orderBy,
      },
    });
  },
  searchOffersByUser_LOCAL_DB(variables) {
    const query = `query searchOffersByUser_LOCAL_DB($id: String, $status: [String], $offerType: [String], $size: Int $offset: Int, $sortBy: ESOffersSortingBy, $orderBy: OrderByChoices, $address: String){
      searchOffersByUser(id: $id, status: $status, offerType: $offerType, size: $size, offset: $offset, sortBy: $sortBy, orderBy: $orderBy, address: $address) {
        offers {
          canceledDate
          depositPaid
          publishedDate
          depositHistory {
            ${DEPOSIT_HISTORY}
          }
          id
          buyerClosingPeriod {
            startDate
            endDate
          }
          BDASignedDate
          declineReason
          SSDocument{
            ${DOCUMENT_FOR_SIGN}
          }
          BDApdfKey
          owners
          buyerClosingDocumentsAccepted
              deposit{
               ${DEPOSIT}
              }
          assets {
            id
            owners
            title
            currentPeriod{
            endDate
            }
            contactsDisclosed
            contacts {
              firstName
              lastName
              officePhone
              emailAddress
              companyName
              info {
                avatar
                jobTitle
              }
              whatsappNumber
              contactAvatar
            }
            noteOverview {
              unpaidPrincipalBalance {
                amount
              }
            }
            principalBalance {
              amount
            }
            bidAllocation {
              amount
            }
            callForOffersPeriod {
              startDate
              endDate
            }
            bestAndFinalPeriod {
              startDate
              endDate
            }
            address {
              address
              city
              state
              country
            }
            name
            images
            status
            type
            assetStatus
          }
          bidPrice
          closing
          dateModified
          dueDiligencePeriod
          earnestMoneyDeposit
          notes
          status
          offerType
          creationDate
          acceptedDate
          winningDate
          declinedDate
          withdrawDate
        }
        total
      }
    }`;

    return API.graphql({
      query,
      variables,
    });
  },
  searchAssetOffersByUser(variables) {
    const query = `query searchAssetOffersByUser($status: [String], $offerType: [String], $size: Int $offset: Int,$assetId:String){
      searchOffersByUser(status: $status, offerType: $offerType, size: $size, offset: $offset , assetId:$assetId) {
        offers {
          id
          BDASignedDate
          assets {
            status
            id
            title
            principalBalance {
              amount
            }
            bidAllocation {
              amount
            }
            images
            noteOverview{
              totalPayoff{
                amount
              }
            }
            address{
              country
              state
              city
              address
              zipCode
            }
          }
          bidPrice
          closing
          dateModified
          dueDiligencePeriod
          earnestMoneyDeposit
          notes
          status
          offerType
        }
        total
      }
    }`;

    return API.graphql({
      query,
      variables,
    });
  },
  getOffer(id) {
    const query = `query getOffer($id: String!) {
            getOffer(id: $id) {
                  id
                  owners
                  deposit {
                    value {
                      amount
                    }
                    fundTransactionId
                  }
                  publishedDate
                  SSHistory {
                    sellerOpened {
                      createdDate
                    }
                  }
                  buyerBankingInfoId
                  sellerBankingInfoId
                  winningDate
                  BDASignedDate
                  buyerClosingDocumentsAccepted
                  sellerClosingDocumentsAccepted
                  buyerClosingPeriod {
                    startDate
                    endDate
                  }
                  sellerClosingPeriod {
                    startDate
                    endDate
                  }
                  SSRecalculated {
                    buyer {
                      name
                      value
                    }
                    seller {
                      name
                      value
                    }
                  }
                  SSDocument { 
                    pdfKey, 
                    signature {
                      seller
                      buyer
                      sellerSignDate
                      buyerSignDate
                    }
                  }
                  assetsDetailed {
                    type
                    id
                    assetStatus
                    name
                    owners
                    creator
                    status
                    images
                    principalBalance {
                      amount
                    }
                    noteOverview {
                      unpaidPrincipalBalance {
                        amount
                      }
                    }
                    address {
                      country
                      state
                      city
                      address
                      zipCode
                    }
                  }
	                assets {
                      id
                      title
	                    principalBalance {
                        amount
                      }
	                    bidAllocation {
                        amount
                      }
                    }
                    asset_owner_token
                    dateModified
                    bidPrice {
                      amount
                    }
	                closing
	                dateModified
	                dueDiligencePeriod
	                earnestMoneyDeposit {
                        amount
                    }
	                notes
	                status
                    type
                    payment {
                        status
                    }
                    contacts {
                        firstName
                        lastName
                    }
                    pofSum {
                        amount
                    }
                    fundsApprovalDate
                }
        }`;

    return API.graphql({
      query,
      variables: { id },
    });
  },
  getOfferDeposit(id) {
    const query = `query getOffer($id: String!) {
      getOffer(id: $id) {
        deposit{
          ${DEPOSIT}
        }
        depositPaid
        depositHistory {
          ${DEPOSIT_HISTORY}
        }
      }
    }`;

    return API.graphql({
      query,
      variables: { id },
    });
  },
  getOffersForAssetSeller(id) {
    const query = `query getOffers($id: String!) {
            getAsset(id: $id) {
                type
                status
                id
                offers {
                    id
                }
            }
        }`;

    return API.graphql({
      query,
      variables: {
        id,
      },
    });
  },
  selectWinner(id) {
    const query = `mutation selectWinner($id: String!) {
            selectWinner(id: $id) {
                ${OFFER}
            }
        }`;

    return API.graphql({
      query,
      variables: {
        id,
      },
    });
  },
  selectForBestAndFinal(id) {
    const query = `mutation selectForBestAndFinal($id: String!) {
            selectForBestAndFinal(id: $id) {
                id
            }
        }`;

    return API.graphql({
      query,
      variables: {
        id,
      },
    });
  },
  declineOffer(variables) {
    const mutation = `mutation decline($id: String! , $declineReason: String) {
            declineOffer(id: $id , declineReason: $declineReason){
              ${OFFER}
              
            }
          }`;

    return API.graphql({
      query: mutation,
      variables,
    });
  },
  listOffersForAsset(id) {
    const query = `query listOffersForAsset($id: String!) {
         listOffersForAsset(id: $id){
          offers{
            id
            status
            notes
            owners
            offerHistory{
              assets{
                id
                title
                principalBalance{
                  amount
                }
                bidAllocation{
                  amount
                }
              }
              bidPrice{
                amount
              }
              closing
              dateModified
              dueDiligencePeriod
              earnestMoneyDeposit{
                amount
              }
              notes
              status
            }
            assets{
              id
              title
              principalBalance{
                amount
              }
              bidAllocation{
                amount
              }
            }
            bidPrice{
              amount
              currency
            }
            pofSum{
              amount
              currency
            
            }
            earnestMoneyDeposit{
              amount
              currency
            }
            contacts{
              firstName
              lastName
              officePhone
              emailAddress
              whatsappNumber
              companyName
              contactAvatar
            }
            type
            dueDiligencePeriod
            closing
            fundsApprovalDate

             }
            }
          }`;

    return API.graphql({
      query,
      variables: {
        id,
      },
    });
  },
  searchOffers(variables) {
    const query = `query searchOffers($assetId: String) {
      searchOffers(assetId: $assetId){
          offers{
            id
            status
            notes
            owners
            offerHistory{
              assets{
                id
                title
                principalBalance{
                  amount
                }
                bidAllocation{
                  amount
                }
              }
              bidPrice{
                amount
              }
              closing
              dateModified
              dueDiligencePeriod
              earnestMoneyDeposit{
                amount
              }
              notes
              status
            }
            assets{
              id
              title
              principalBalance{
                amount
              }
              bidAllocation{
                amount
              }
              assetStatus
              status
            }

            bidPrice
    
            pofSum{
              amount
              currency
            
            }
            earnestMoneyDeposit
            contacts{
              firstName
              lastName
              officePhone
              emailAddress
              whatsappNumber
              companyName
              contactAvatar
            }
            type
            dueDiligencePeriod
            closing
            fundsApprovalDate

             }
            }
          }`;

    return API.graphql({
      query,
      variables,
    });
  },
};
