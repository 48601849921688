import { API } from "aws-amplify";
const isAuth = localStorage.getItem("authenticated");
export default () => {
  const query = `query getSettings {
          getSettings {
            autoDeposit
            userProfileRequired
            userNotificationsCriteriaRequired
            passwordlessRegistrationEnabled
          }
      }`;
  return API.graphql({
    query: query,
    variables: {},
    authMode: isAuth ? "" : "AWS_IAM",
  });
};
