import { editOfferGuard } from "@/routerGuards/guardEditOffer";

export default [
  {
    path: "/buy-commercial-real-estate-loans/assets/:id?",
    name: "make-offer-assets",
    component: () => import("./components/MakeOfferFlow/Assets.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
  {
    path: "/buy-commercial-real-estate-loans/deposit-agreement/:id?",
    name: "make-offer-signa",
    component: () =>
      import("./components/MakeOfferFlow/BidDepositAgreement.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
  {
    path: "/buy-commercial-real-estate-loans/offer-info/:id?",
    name: "make-offer-form",
    component: () => import("./components/MakeOfferFlow/OfferInfo.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
  {
    path: "/buy-commercial-real-estate-loans/review-submit/:id",
    name: "make-offer-review",
    component: () => import("./components/MakeOfferFlow/ReviewSubmit.vue"),
    beforeEnter: editOfferGuard,
    meta: {
      hideFooter: true,
      makeOfferFlow: true,
    },
  },
];
