<template>
  <v-select
    v-on="$listeners"
    v-bind="$attrs"
    chips
    :menu-props="{
      'offset-y': true,
      bottom: bottom,
      top: top,
      allowOverflow: true,
      'content-class': hideMenu ? 'd-none' : contentMenuCustomMenu,
    }"
    item-color="#2969FF"
    class="asset-status second-select"
    :multiple="!single"
    :class="$attrs.errorClass"
    :rules="rules"
    :attach="attach"
    @change="$emit(`change`)"
  >
    <template v-slot:selection="{ item, index }">
      <template v-if="short && !isMobile">
        <v-chip
          v-if="index === 0"
          color="white"
          close-icon="mdi-close"
          close
          @click:close="deleteChip($attrs.value)"
        >
          {{ getValuesCount(item, $attrs) }}
        </v-chip>
      </template>
      <template v-else>
        <v-chip
          v-if="index === 0 && !isMobile"
          color="white"
          close-icon="mdi-close"
          close
          @click:close="deleteChip($attrs.value)"
        >
          <span class="chip-text">
            {{
              item.name.length > 12 && slicedValue
                ? item.name.slice(0, 12) + "..."
                : item.name
            }}
          </span>
        </v-chip>
        <v-chip
          v-if="!isMobile && index === 1"
          color="white"
          close-icon="mdi-close"
          close
          @click:close="deleteHiddenChips($attrs.value)"
        >
          + {{ $attrs.value.length - 1 }}
        </v-chip>
        <v-chip
          v-if="isMobile && index === 0"
          color="white"
          close-icon="mdi-close"
          close
          @click:close="deleteHiddenChips($attrs.value)"
        >
          + {{ $attrs.value.length }}
        </v-chip>
      </template>
    </template>
    <template v-slot:append v-if="$vuetify.breakpoint.xs && itemsCount > 0">
      <div class="items-count-wrapper">
        {{ itemsCount }}
      </div>
    </template>

    <template v-slot:append v-else>
      <v-list-item-action v-if="customAppend" class="custom-icon">
        <img :src="selectIcon" />
      </v-list-item-action>
    </template>
  </v-select>
</template>
<script>
import selectIcon from "@/assets/img/select-icon.svg";
export default {
  name: "SecondSelect",
  props: {
    attach: {
      type: [Boolean, String],
      default: false,
    },
    contentMenuCustomMenu: {
      type: String,
      default: "select-menu",
    },
    short: {
      type: Boolean,
      default: false,
    },
    slicedValue: {
      type: Boolean,
      default: false,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    itemsCount: {
      type: Number,
      default: 0,
    },
    bottom: {
      type: Boolean,
      default: true,
    },
    top: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    customAppend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectIcon,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getValuesCount(item, attrs) {
      const { name = "" } = item;
      const { value = "" } = attrs ?? {};
      const maxLength = 10;
      const additionalCount =
        value.length - 1 === 0 ? "" : `+${value.length - 1}`;
      if (name.length && name.length > maxLength && this.slicedValue) {
        return `${name.slice(0, maxLength)}... ${additionalCount}`;
      }
      return `${name} ${additionalCount}`;
    },
    deleteChip(array) {
      if (!this.single) {
        let newVal = array.filter((item, i) => i !== 0);
        this.updateValue(newVal);
      } else {
        this.updateValue(null);
      }
    },
    deleteHiddenChips(array) {
      let newVal = array.filter((item, i) => i === 0);
      this.updateValue(newVal);
    },
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped lang="scss">
.custom-icon {
  justify-content: end;
}
::v-deep {
  .items-count-wrapper {
    background: #dd226f;
    border-radius: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #ffffff;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
  }
  .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>
