import Deposit from "@/services/apiCalls/deposit/Deposit";
import ErrorParser from "@/utils/ErrorParser";

export default {
  namespaced: true,
  state: {
    payment: null,
  },
  getters: {},
  mutations: {
    setPayment(state, value) {
      state.payment = value;
    },
  },
  actions: {
    async editDepositAmount({ state, commit }, { offerId, deposit }) {
      const result = await Deposit.editDepositAmount({
        offerId,
        deposit,
      })
        .then(({ data }) => {
          return data.editDepositAmount;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getPayment({ state, commit }, id) {
      const result = await Deposit.getPayment(id)
        .then(({ data }) => {
          const { status } = data.getPayment;
          return status;
        })
        .catch((err) => {
          ErrorParser(err);
          return "Error";
        });
      return result;
    },
    async getFailedDocumentsAssetsForSeller({ state, commit }) {
      const result = await Deposit.getFailedDocumentsAssets()
        .then(({ data }) => {
          const { getFailedDocumentsAssets: assets } = data ?? {};
          // const { assets } = data.getFailedDocumentsAssets ?? {};
          return {
            assets,
          };
        })
        .catch((err) => {
          ErrorParser(err);
          return "Error";
        });
      return result;
    },
    async getFailedDocumentsAssetsForAdmin({ state, commit }) {
      const result = await Deposit.getFailedDocumentsAssetsForAdmin()
        .then(({ data }) => {
          const { getFailedDocumentsAssetsForAdmin: assets } = data ?? {};
          // const { assets, total } = data.getFailedDocumentsAssetsForAdmin ?? {};
          return {
            assets,
          };
        })
        .catch((err) => {
          ErrorParser(err);
          return "Error";
        });
      return result;
    },
  },
};
