import { VALUE_AMOUNT } from "../Global/ValueAmount";
import { PERIOD } from "../Global/Period";
export const ASSET = `
id
name
owners
customCADoc
caConfirmed
BDAConfirmed
TOCConfirmed
description
dateModified
status
statusComment
creationDate
type
upbDate
sellerClosingDocumentsAccepted
winnerOffer {
  id
  sellerBankingInfoId
  assetsDetailed {
    id
  }
}
winnerOfferId
contacts{
    firstName
  lastName
  officePhone
  emailAddress
  companyName
  contactAvatar
  whatsappNumber
}
assetStatus
principalBalance{
    ${VALUE_AMOUNT}
}
listingPrice{
    ${VALUE_AMOUNT}
}
bidDepositSum{
    ${VALUE_AMOUNT}
}
callForOffersPeriod{
    ${PERIOD}
}
bestAndFinalPeriod{
    ${PERIOD}
    
}
underSelectionPeriod{
    ${PERIOD}
}
numberOfOffers
numberOfActiveOffers
numberOfAllOffers
numberOfActiveOffers
numberOfCAs
numberOfTier2s
numberOfAllActivities
numberOfViewedOnly
images
address{
    country
    state
    city
    address
    zipCode
}

noteOverview {
    numberOfLoans
    originationDate
    maturityDate
    loanAmount {
        amount
    }
    unpaidPrincipalBalance {
        amount
        currency
    }
    totalPayoff {
        amount
        currency
    }
    estCollateralValue {
        amount
        currency
    }
    initialInterestType
    initialInterestRate
    currentInterestRate
    defaultInterestRate
    term
    amortization
    recourse
    modified
    assumptionOfLoanAllowed
    prePaymentLimitations
    penalty
    monetaryDefault
    maturityDefault
    technicalDefault
}
`;
