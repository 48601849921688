// import axios from "axios";
// import { Auth } from "aws-amplify";

/**
 * Integration message types
 * @readonly
 * @enum {string}
 */
export const messageType = {
  REGISTER_USER: "registrationUser",
  ENCRYPT_FILE: "encryptData",
};
import { Zentinel } from "@prettyfluid/zentinel";

/**
 * @type {Map<string, Set>}
 */
import zentinelToken from "@/services/apiCalls/zentinelToken";
import app_config from "@/app_config";

export default {
  async install(Vue) {
    // // eslint-disable-next-line no-console
    // console.log("app_config.ZENTINEL_URL", app_config.ZENTINEL_URL);
    // // eslint-disable-next-line no-console
    // console.log("app_config.ZENTINEL_CLIENT_ID", app_config.ZENTINEL_CLIENT_ID);
    // // eslint-disable-next-line no-console
    // console.log(
    //   "app_config.ZENTINEL_CATEGORY_ID",
    //   app_config.ZENTINEL_CATEGORY_ID
    // );
    // // eslint-disable-next-line no-console
    // console.log("app_config.ZENTINEL_API_URL", app_config.ZENTINEL_API_URL);
    // // eslint-disable-next-line no-console
    // console.log("ZENTINEL_SITE_URL", app_config.ZENTINEL_SITE_URL);
    const zentinel = new Zentinel({
      zentinelSiteUrl: app_config.ZENTINEL_SITE_URL, //url of zentinel web app
      url: app_config.ZENTINEL_URL, //url of zentinel web-agent with a slash on the end
    });

    const userProvider = app_config.ZENTINEL_CLIENT_ID; // Organization name from javaagent
    const user = await zentinel.sendMessage("anonymous-login", {
      userProvider,
    });
    const categoryId = app_config.ZENTINEL_CATEGORY_ID;
    const dataOwnerId = user.id;
    const clientId = app_config.ZENTINEL_CLIENT_ID;
    const mapper = await zentinel.initDataMapper(categoryId);

    Vue.prototype.$zentinel = {
      /**
       * Max file size in bytes
       */
      MAX_FILE_SIZE: 5000000,

      /**
       * @return {Promise<{id: number, encryptionPublicKey: string}>} Data used for file encryption
       */
      async getEncryptionDetails() {},

      /**
       * @param {string} key
       * @return {Promise<string>} base64 data url
       */
      async decryptDocument(key, filename, documentOwnerId) {
        const externalId = key;
        try {
          const { data } = await zentinelToken.getToken();

          const url = `${
            app_config.ZENTINEL_API_URL
          }/category-records?owner-id=${
            documentOwnerId ? documentOwnerId : dataOwnerId
          }&external-id=${externalId}&category-id=${categoryId}`;
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
              Token: data.getZentinelToken,
            },
          });
          const decryptedData = await response.json();
          const downloadUrl = decryptedData.file1;

          var elem = document.createElement("a");
          elem.href = downloadUrl;
          elem.target = "hiddenIframe";
          elem.setAttribute("download", filename);
          elem.click();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("err", err);
        }
      },

      /**
       * @param {OutgoingMessage} message
       */
      async uploadFile(base64File) {
        const data = {
          file1: base64File,
        };
        const result = await mapper.saveData(data, clientId);

        return {
          result,
          dataOwnerId,
        };
      },

      /**
       * @param {messageType} type Message type
       * @param {number} timeout Max response time in ms
       * @return Promise<ReceivedMessage>
       */
      async listenForOne(type, timeout = 0, queryTimeout = 60000) {},
    };
  },
};
