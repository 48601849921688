import Offer from "@/services/apiCalls/offer/offer";
import ErrorParser from "@/utils/ErrorParser";
import BDA from "@/services/apiCalls/bda/bda";
import Asset from "@/services/apiCalls/asset/asset";
import {
  converOfferToESOfferWithDetailedAsset,
  convertOfferToESOffer,
} from "@/utils/mappers/offer/OfferToESOffer";
import DBInterface from "@/services/localDatabase/DBInterface";
import { LOCAL_DATABASE } from "@/constants/localDatabase";

export default {
  namespaced: true,
  state: {
    asset: null,
    offer: null,
    bdaLink: "",
    bdaParams: {
      sign: null,
      check: false,
    },
    activeListingOfferStatus: false,
    offerParams: null,
    paramsForEdit: null,
    selectedAssets: [],
    pathForFinishLater: "",
    existedOffers: [],
  },
  getters: {},
  mutations: {
    setAsset(state, value) {
      state.asset = value;
    },
    setExistedOffers(state, value) {
      state.existedOffers = value;
    },
    setBdaParams(state, value) {
      state.bdaParams = value;
    },
    setOfferParams(state, value) {
      state.offerParams = value;
    },
    setParamsForEdit(state, value) {
      state.paramsForEdit = value;
    },
    setOffer(state, value) {
      state.offer = value;
    },
    setOfferDeposit(state, deposit) {
      state.offer = {
        ...state.offer,
        deposit,
      };
    },
    setSelectedAssets(state, value) {
      state.selectedAssets = value;
    },
    setPathForFinishLater(state, value) {
      state.pathForFinishLater = value;
    },
    setBDALink(state, value) {
      state.bdaLink = value;
    },
    setActiveListingOfferStatus(state, value) {
      state.activeListingOfferStatus = value;
    },
    resetInfo(state, value) {
      state.bdaLink = "";
      state.asset = null;
      state.offer = null;
      state.bdaParams = {
        sign: null,
        check: false,
      };
      state.paramsForEdit = null;
      state.activeListingOfferStatus = false;
      state.offerParams = null;
      state.selectedAssets = [];
      state.pathForFinishLater = "";
      sessionStorage.removeItem("makeOfferLocalData");
      sessionStorage.removeItem("activeListingOfferStatusLocalData");
    },
  },
  actions: {
    async fetchOffer({ state, commit }, id) {
      const result = await Offer.getOffer(id)
        .then(({ data }) => {
          if (data.getOffer) {
            const offer = data.getOffer;
            commit("setOffer", offer);

            const params = {
              bidPriceNumber: offer.bidPrice.amount,
              earnestMoneyDeposit: offer.earnestMoneyDeposit.amount,
              dueDiligencePeriod: offer.dueDiligencePeriod,
              closing: offer.closing,
            };

            commit("setOfferParams", params);
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async createOffer({ rootState, commit }, params) {
      let newOffer = null;
      const result = await Offer.createOffer(params)
        .then(async ({ data }) => {
          // eslint-disable-next-line no-console
          console.log("create offer data", data);
          if (data.createOffer) {
            const convertedOffer = converOfferToESOfferWithDetailedAsset(
              data.createOffer,
              data.createOffer
            );
            console.log("create CONVERTED OFFER", convertedOffer);
            commit("buyerDashboard/ADD_OFFER", convertedOffer, { root: true }); // Specify module name
            newOffer = convertedOffer;
            // await rootState.localDatabase.put(
            //   LOCAL_DATABASE.OFFERS,
            //   convertedOffer
            // );
            return data.createOffer;
          }

          console.log(11);
          return data;
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            {
              text: ErrorParser(err),
              color: "error",
            },
            { root: true }
          );
          return false;
        })
        .finally((resp) => {
          if (newOffer) {
            rootState.localDatabase.put(LOCAL_DATABASE.OFFERS, newOffer);
          }
          return resp;
        });
      return result;
    },
    async editOffer({ rootState, commit }, params) {
      let newOffer = null;
      const result = await Offer.editOffer(params)
        .then(async ({ data }) => {
          console.log("3 - edit offer data", data);
          if (data.editOffer) {
            data.editOffer.assets = data.editOffer.assets.map((asset, idx) => {
              return {
                ...asset,
                ...data.editOffer.assetsDetailed[idx],
              };
            });
            console.log("3.5 data.editOffer - ", data.editOffer);
            const convertedOffer = converOfferToESOfferWithDetailedAsset(
              data.editOffer,
              data.editOffer
            );
            console.log("4 - edit CONVERTED OFFER", convertedOffer);
            commit(
              "buyerDashboard/UPDATE_OFFER_BY_ID",
              { offerToUpdateId: data.editOffer.id, newOffer: convertedOffer },
              { root: true }
            );

            newOffer = convertedOffer;
            // await rootState.localDatabase.put(
            //   LOCAL_DATABASE.OFFERS,
            //   convertedOffer
            // );
          }

          console.log(5);
          return data.editOffer;
        })
        .catch((err) => {
          console.log(6);
          commit(
            "openSnackBar",
            {
              text: ErrorParser(err),
              color: "error",
            },
            { root: true }
          );
          return false;
        })
        .finally((resp) => {
          console.log(7, newOffer);
          if (newOffer) {
            rootState.localDatabase.put(LOCAL_DATABASE.OFFERS, newOffer);
          }
          console.log(8, resp);
          return resp;
        });
      console.log(9, result);
      return result;
    },
    async publishOffer({ commit, rootState }, id) {
      let newOffer = null;
      const result = await Offer.publishOffer(id)
        .then(async ({ data }) => {
          console.log("publish offer data", data);
          data.publishOffer.assets = data.publishOffer.assets.map(
            (asset, idx) => {
              return {
                ...asset,
                ...data.publishOffer.assetsDetailed[idx],
              };
            }
          );

          const convertedOffer = converOfferToESOfferWithDetailedAsset(
            data.publishOffer,
            data.publishOffer
          );
          console.log("publish CONVERTED OFFER", convertedOffer);
          commit(
            "buyerDashboard/UPDATE_OFFER_BY_ID",
            {
              offerToUpdateId: data.publishOffer.id,
              newOffer: convertedOffer,
            },
            { root: true }
          ); // Specify module name

          newOffer = convertedOffer;
          // await rootState.localDatabase.put(
          //   LOCAL_DATABASE.OFFERS,
          //   convertedOffer
          // );

          console.log(33);
          return data;
        })
        .catch((err) => {
          commit(
            "openSnackBar",
            {
              text: ErrorParser(err),
              color: "error",
            },
            { root: true }
          );
          return false;
        })
        .finally((resp) => {
          if (newOffer) {
            rootState.localDatabase.put(LOCAL_DATABASE.OFFERS, newOffer);
          }
          return resp;
        });
      return result;
    },
    async generateBDADocument({ commit }, value) {
      await BDA.generateBDADocument()
        .then(({ data }) => {
          if (data.generateBDADocument) {
            commit("setBDALink", data.generateBDADocument);
          }
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
    },
    async signBDADocument({ commit }, { id, signature }) {
      const result = await BDA.signBDADocument(id, signature)
        .then(({ data }) => {
          // if (data.signBDADocument) {
          //
          // }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getUserDocument({ commit }, { id, key }) {
      const result = await Asset.getUserDocument(id, key)
        .then(({ data }) => {
          if (data.getUserDocument) {
            commit("setBDALink", data.getUserDocument);
            return data.getUserDocument;
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
    async getOffersForAssetSeller({ commit }, assetId) {
      const result = await Offer.searchAssetOffersByUser(assetId)
        .then(({ data }) => {
          if (data.searchOffersByUser) {
            commit("setExistedOffers", data.searchOffersByUser.offers);
            return data.searchOffersByUser;
          }
          return true;
        })
        .catch((err) => {
          ErrorParser(err);
          return false;
        });
      return result;
    },
  },
};
