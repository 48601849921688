import countBuyerOffers from "@/services/apiCalls/offer/queries/countBuyerOffers";
import Offer from "@/services/apiCalls/offer/offer";
import ErrorParser from "@/utils/ErrorParser";
import { converOfferToESOfferWithDetailedAsset } from "@/utils/mappers/offer/OfferToESOffer";
import DBInterface from "@/services/localDatabase/DBInterface";
import { LOCAL_DATABASE } from "@/constants/localDatabase";

export default {
  namespaced: true,
  state: {
    offerTotalCount: null,
    offersList: [],
    selectedOffer: {},
    offerFilter: null,
  },
  getters: {},
  mutations: {
    setOfferFilter(state, filter) {
      state.offerFilter = filter;
    },
    setTotalCount(state, count) {
      state.offerTotalCount = count;
    },
  },
  actions: {
    async editOffer({ commit, rootState }, params) {
      const result = await Offer.editOffer(params)
        .then(async ({ data }) => {
          data.editOffer.assets = data.editOffer.assets.map((asset, idx) => {
            return {
              ...asset,
              ...data.editOffer.assetsDetailed[idx],
            };
          });
          const convertedOffer = converOfferToESOfferWithDetailedAsset(
            data.editOffer,
            data.editOffer
          );
          console.log("offers.JS edit offer action", convertedOffer);
          commit(
            "buyerDashboard/UPDATE_OFFER_BY_ID",
            { offerToUpdateId: data.editOffer.id, newOffer: convertedOffer },
            { root: true }
          ); // Specify module name
          await rootState.localDatabase.put(
            LOCAL_DATABASE.OFFERS,
            convertedOffer
          );
          return true;
        })
        .catch((err) => {
          return ErrorParser(err);
        });
      return result;
    },
    async countBuyerOffers({ commit }, params) {
      await countBuyerOffers(params)
        .then((resp) => {
          if (resp) {
            commit("setTotalCount", resp.data.searchOffersByUser.total);
          }
        })
        .catch((err) => {
          ErrorParser(err);
        });
    },
    async searchOfferByUser({ commit }, params) {
      const {
        id,
        offset,
        size,
        status,
        type: offerType,
        address,
        sortBy,
        orderBy,
      } = params || {};

      return await Offer.searchOffersByUser(
        id,
        status,
        offerType,
        offset,
        size,
        address,
        sortBy,
        orderBy
      ).catch((err) => {
        ErrorParser(err);
      });
    },
    async withdrawOffer({ commit }, offerId) {
      return await Offer.withdrawOffer(offerId)
        .then(() => {
          return true;
        })
        .catch((err) => {
          return ErrorParser(err);
        });
    },
    async searchOffers({}, params) {
      return await Offer.searchOffers(params);
    },
  },
};
