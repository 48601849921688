<template>
  <v-tooltip bottom content-class="second-tooltip">
    <template v-slot:activator="{ on, attrs }">
      <div class="icon-wrapper px-2" v-bind="attrs" v-on="on">
        <v-icon
          size="25"
          :disabled="disabled"
          class="cursor-pointer"
          @click="onChatIconClick"
        >
          mdi-chat
        </v-icon>
        <span v-if="getNewMsgCounter" class="red-dot">
          {{ getNewMsgCounter }}
        </span>
      </div>
    </template>
    <span>Open/Close chat</span>
  </v-tooltip>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "AppBarChat",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("Chat", ["openedPanel"]),
    ...mapGetters("Chat", ["getNewMsgCounter"]),
  },
  methods: {
    ...mapMutations("Chat", ["toggleChatPanel"]),
    onChatIconClick() {
      this.toggleChatPanel();
    },
  },
};
</script>
<style scoped lang="scss">
.icon-wrapper {
  position: relative;
}
.red-dot {
  bottom: -2px;
  right: 8px;
  background: #dd226f;
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #00136d;
  font-size: 8px;
  text-align: center;
}
</style>
