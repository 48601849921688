import { render, staticRenderFns } from "./NavigationButtons.vue?vue&type=template&id=1e1fbfef&scoped=true"
import script from "./NavigationButtons.vue?vue&type=script&lang=js"
export * from "./NavigationButtons.vue?vue&type=script&lang=js"
import style0 from "./NavigationButtons.vue?vue&type=style&index=0&id=1e1fbfef&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e1fbfef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
