<template>
  <div id="conversation">
    <div class="conversation-header px-3">
      <div class="conversation-header-content pt-3 pb-1">
        <div class="avatar-section">
          <ChatAvatar
            :avatar="getAvatarData.avatar"
            :color="getAvatarData.color"
            :user="getAvatarData.user"
          ></ChatAvatar>
        </div>
        <div class="info-section pl-3">
          <v-row no-gutters>
            <v-col>
              <div
                class="conversation-header-title cursor-pointer"
                @click="redirectToAsset(getAssetTitle.link)"
              >
                {{ getAssetTitle.title }}
              </div>
              <div class="conversation-header-name">
                {{ getRecipient }}
              </div>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-icon @click="closeChat" class="close-btn cursor-pointer">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="conversation-container" ref="messageContainer">
      <div
        v-for="(msgGroup, date) in groupedMessages"
        :key="date"
        class="bubble-group-container"
      >
        <div class="date-separator text-center">{{ date }}</div>
        <div
          v-for="(message, msgIdx) in msgGroup"
          :key="msgIdx"
          class="bubble-container text-left"
        >
          <ConversationMessage :message="message"></ConversationMessage>
        </div>
      </div>
    </div>
    <div class="input-container px-4 pb-3">
      <div class="msg-wrapper pb-3">
        <v-textarea
          v-model="messageText"
          height="86px"
          placeholder="Write a message..."
          class="text-area"
          filled
          hide-details
          no-resize
          @keyup.enter.prevent.stop="sendMessage"
        ></v-textarea>
      </div>
      <div class="actions-line">
        <!--        <v-icon class="cursor-pointer mr-3"> mdi-paperclip </v-icon>-->
        <v-icon class="cursor-pointer" @click="sendMessage"> mdi-send </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ConversationMessage from "@/components/Chat/ConversationMessage.vue";
import ChatAvatar from "@/components/Chat/ChatAvatar.vue";
import getHashCode from "@/utils/HashCode";
import getInitials from "@/utils/Initials";

export default {
  name: "ConversationComponent",
  components: { ChatAvatar, ConversationMessage },
  props: {
    activeConversation: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      messages: [],
      messageText: "",
      isSignedInUser: false,
    };
  },
  computed: {
    ...mapState("Chat", [
      "sellerChats",
      "buyerChats",
      "selectedChatType",
      "selectedConversation",
    ]),
    getAvatarData() {
      if (this.chat) {
        if (this.chat.sellerID === this.$store.getters.user.id) {
          return {
            avatar: this.chat.buyerInfo.sellerProfile?.avatar,
            color: this.chat.buyerInfo.color,
            user: this.chat.buyerInfo,
          };
        } else {
          return {
            avatar: this.chat.sellerInfo.sellerProfile?.avatar,
            color: this.chat.sellerInfo.color,
            user: this.chat.sellerInfo,
          };
        }
      }
      return {
        avatar: "",
        color: "",
        user: "",
      };
    },
    chat() {
      return this.selectedConversation;
    },
    getRecipient() {
      if (this.chat && this.selectedChatType === "buyer") {
        if (this.chat.sellerInfo.anonymous) {
          return "Asset Seller";
        }
        return (
          this.chat.sellerInfo.firstName &&
          `${this.chat.sellerInfo.firstName} ${this.chat.sellerInfo.lastName}`
        );
      } else if (this.chat && this.selectedChatType === "seller") {
        return `${this.chat.buyerInfo.firstName} ${this.chat.buyerInfo.lastName}`;
      }
      return "";
    },
    getAssetTitle() {
      if (this.chat) {
        const { assetInfo } = this.chat ?? {};
        const { name, assetStatus, id } = assetInfo ?? {};
        const linkStatus = assetStatus
          .reduce((acc, item) => acc + item, "")
          .replace(/ /g, "-")
          .toLowerCase();

        let linkName = name.replace(/ /g, "-").toLowerCase();
        linkName = name.replace(/[\/ ]/g, "-");

        return {
          title: `${name} - ${assetStatus.join("-")}`,
          link: `/buy-commercial-real-estate-loans/${linkStatus}-${linkName}-${id}`,
        };
      }
      return {};
    },
    groupedMessages() {
      if (this.messages.length) {
        const grouped = this.messages.reduce((acc, { state }) => {
          const date = this.$moment(state.dateUpdated).format("MMM D");
          acc[date] = acc[date] || [];
          acc[date].push({ state });
          return acc;
        }, {});
        return grouped;
      }
      return {};
    },
  },
  watch: {
    activeConversation: {
      handler(newVal) {
        if (!newVal) return;

        this.setMessages();

        this.activeConversation.on("messageAdded", async (message) => {
          const lastMsg = this.messages[this.messages.length - 1];
          if (lastMsg?.state.sid !== message?.state.sid) {
            this.messages = [...this.messages, message];

            this.activeConversation.setAllMessagesRead();
            // this.setAllMessagesRead();

            setTimeout(() => {
              this.scrollToBottom();
            }, 100);
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("Chat", ["resetChat", "setSellerChats", "setBuyerChats"]),
    ...mapActions("Chat", ["noticeMessageAdded"]),
    redirectToAsset(link) {
      this.$router.push(link);
    },
    closeChat() {
      this.$emit("closeChat");
      this.resetChat();
    },
    async setMessages() {
      this.messages = [];
      this.activeConversation.setAllMessagesRead();
      // -
      // this.setAllMessagesRead();
      // -
      await this.activeConversation.getMessages().then((newMessages) => {
        this.messages = [...this.messages, ...newMessages.items];
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);
      });
    },
    sendMessage() {
      if (this.messageText.trim().length) {
        this.activeConversation.sendMessage(this.messageText).then(async () => {
          const attributes = {
            ...this.selectedConversation.attributes,
            lastMessage: this.messageText,
            lastMessageOwnerId: this.$store.state.user.id,
          };
          this.activeConversation.updateAttributes(attributes);

          const msgParams = {
            msgText: this.messageText,
            senderID: this.$store.state.user.id,
            receiverID: this.getReceiverId(),
            chatTwilioSID: this.chat.twilioSID,
          };
          await this.noticeMessageAdded(msgParams);
          await this.$nextTick();

          this.messageText = "";
          this.scrollToBottom();
        });
      }
    },
    getReceiverId() {
      const chat = this.chat ?? {};
      return chat.sellerID === this.$store.state.user.id
        ? this.chat.buyerID
        : this.chat.sellerID;
    },
    scrollToBottom() {
      if (this.$refs.messageContainer) {
        this.$refs.messageContainer.scrollTop =
          this.$refs.messageContainer.scrollHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#conversation {
  position: absolute;
  right: calc(100% + 24px);
  bottom: 0;
  border-radius: 20px 20px 0 0;
  background: #ffffff;
  box-shadow: 0 8px 25px 0 rgba(0, 17, 84, 0.25);

  .conversation-header {
    min-height: 72px;

    .conversation-header-content {
      border-bottom: 1px solid #979797;
      height: 100%;
      display: flex;

      .avatar-section {
        display: flex;
        align-items: center;
        justify-content: center;

        .anonymous-abbr {
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);
        }
      }
      .info-section {
        flex: 1;
      }
      .conversation-header-title {
        color: #2969ff;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  .input-container {
    .text-area {
      height: 86px;
    }
    .actions-line {
      display: flex;
      justify-content: right;
    }

    .msg-wrapper {
      ::v-deep {
        .v-text-field--filled > .v-input__control > .v-input__slot {
          border-radius: 1.333px;
          background: #f5f5f5;
        }
        .v-text-field > .v-input__control > .v-input__slot:after {
          display: none;
        }
      }
    }
  }
}
.conversation-container {
  width: 520px;
  height: 554px;
  padding: 12px;
  overflow: auto;

  .bubble-group-container {
    border-top: 1px solid #979797;
    padding-top: 10px;

    &:first-child {
      border-top: none;
    }
  }
}

.name {
  padding-right: 8px;
  font-size: 11px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  #conversation .conversation-header {
    min-height: auto;
  }
  .twilio-chat #conversation {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    height: 100%;
    display: grid;
    grid-template-rows: auto 392px auto;
    min-height: 635px;
  }
  .conversation-container {
    width: 100%;
    overflow: auto;
    height: 392px;
  }
}
</style>
