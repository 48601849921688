import updateSellerClosingDocumentsAcceptedInAsset from "@/services/apiCalls/asset/mutations/updateSellerClosingDocumentsAcceptedInAsset";
import { LOCAL_DATABASE } from "@/constants/localDatabase";

export default {
  namespaced: true,
  state: {
    userAssets: [],
    loadedAssets: false,
  },
  getters: {},
  mutations: {
    setUserAssets(state, payload) {
      state.userAssets = payload;
      state.loadedAssets = true;
    },
    updateAssetByIndex(state, { assetIndex, newAsset }) {
      state.userAssets.splice(assetIndex, 1, newAsset);
    },
    updateAssetById(state, { assetId, newAsset }) {
      const assetIndex = state.userAssets.findIndex(
        (asset) => asset.id === assetId
      );
      const assetToUpdate = state.userAssets.find(
        (asset) => asset.id === assetId
      );
      if (assetIndex < 0) {
        return;
      }
      state.userAssets.splice(assetIndex, 1, {
        ...assetToUpdate,
        ...newAsset,
      });
    },
    replaceAssetById(state, { assetId, newAsset }) {
      const assetIndex = state.userAssets.findIndex(
        (asset) => asset.id === assetId
      );
      if (assetIndex < 0) {
        return;
      }
      state.userAssets.splice(assetIndex, 1, newAsset);
    },
    removeAssetById(state, { assetId }) {
      state.userAssets = state.userAssets.filter(
        (asset) => asset.id !== assetId
      );
    },
    removeAssetByValuationId(state, { valuationId }) {
      state.userAssets = state.userAssets.filter(
        (asset) => asset.calculatorResult?.id !== valuationId
      );
    },

    addAsset(state, payload) {
      state.userAssets = [payload, ...state.userAssets];
    },
    convertValuationToAsset(state, { assetValuationId, newAsset }) {
      const valuation = state.userAssets.find(
        (asset) => asset?.calculatorResult?.id === assetValuationId
      );
      const assetToPush = {
        ...valuation,
        ...newAsset,
        status: newAsset.status,
        name: newAsset.name,
        principalBalance: newAsset.principalBalance.amount,
        bestAndFinalPeriod: newAsset.bestAndFinalPeriod,
        callForOffersPeriod: newAsset.callForOffersPeriod,
        underSelectionPeriod: newAsset.underSelectionPeriod,
      };
      state.userAssets = [assetToPush, ...state.userAssets];
    },
  },
  actions: {
    async updateSellerClosingDocumentsAcceptedInAsset(
      { commit },
      { id, sellerClosingDocumentsAccepted }
    ) {
      const res = await updateSellerClosingDocumentsAcceptedInAsset({
        id,
        sellerClosingDocumentsAccepted,
      });
      const asset = res.data.updateSellerClosingDocumentsAcceptedInAsset;
      commit("updateAssetById", { assetId: id, newAsset: asset });
    },
    async updateAsset({ commit, rootState }, newAsset) {
      // eslint-disable-next-line no-console
      console.log("update asset action", newAsset);
      await rootState.localDatabase
        .put(LOCAL_DATABASE.USER_ASSETS, {
          ...newAsset,
          bidAllocation: newAsset.bidAllocation,
        })
        .catch((err) => console.log("err localDb", err));
      commit("updateAssetById", { assetId: newAsset.id, newAsset });
    },
    async createAsset({ commit, rootState }, newAsset) {
      await rootState.localDatabase
        .put(LOCAL_DATABASE.USER_ASSETS, {
          ...newAsset,
          bidAllocation: newAsset.bidAllocation,
        })
        .catch((err) => console.log("err localDb", err));
      commit("addAsset", newAsset);
    },
  },
};
