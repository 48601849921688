<template>
  <v-form>
    <p class="cardPopUp__description title-description" v-if="!formTitle">
      {{ registrationText }}
    </p>
    <p class="cardPopUp__description" v-else>
      {{ formTitle }}
    </p>
    <div class="second-input-with-background email">
      <c-label> Email </c-label>
      <v-text-field
        v-model="email"
        placeholder="user@mail.com"
        :color="inputColor"
        :error-messages="emailErrors"
        @blur="$v.email.$touch()"
        @input="$v.email.$touch()"
      ></v-text-field>
    </div>
    <!--    <div class="second-input-with-background email-repeat">-->
    <!--      <v-text-field-->
    <!--        @contextmenu="(e) => e.preventDefault()"-->
    <!--        @paste="(e) => e.preventDefault()"-->
    <!--        v-model="repeatEmail"-->
    <!--        placeholder="Repeat Email"-->
    <!--        :color="inputColor"-->
    <!--        :error-messages="repeatEmailErrors"-->
    <!--        @blur="$v.repeatEmail.$touch()"-->
    <!--        @input="$v.repeatEmail.$touch()"-->
    <!--      ></v-text-field>-->
    <!--    </div>-->
    <div class="second-input-with-background">
      <c-label> Password </c-label>
      <v-text-field
        v-model="password"
        placeholder="Insert Password"
        :color="inputColor"
        :error-messages="passwordErrors"
        :type="passwordVisible ? 'text' : 'password'"
        @blur="$v.password.$touch()"
        @input="$v.password.$touch()"
      >
        <template v-slot:append>
          <v-icon
            class="custom-eye-icon"
            size="16"
            @click="passwordVisible = !passwordVisible"
            tabindex="-1"
            >{{ passwordVisible ? "mdi-eye" : "mdi-eye-off" }}</v-icon
          >
        </template>
      </v-text-field>
    </div>
    <!--    <div class="second-input-with-background">-->
    <!--      <v-text-field-->
    <!--        class="password-wrapper"-->
    <!--        v-model="repeatPassword"-->
    <!--        placeholder="Repeat Password"-->
    <!--        :color="inputColor"-->
    <!--        :error-messages="repeatPasswordErrors"-->
    <!--        :type="repeatPasswordVisible ? 'text' : 'password'"-->
    <!--        @blur="$v.repeatPassword.$touch()"-->
    <!--        @input="$v.repeatPassword.$touch()"-->
    <!--        @contextmenu="(e) => e.preventDefault()"-->
    <!--        @paste="(e) => e.preventDefault()"-->
    <!--      >-->
    <!--        <template v-slot:append>-->
    <!--          <v-icon-->
    <!--            class="custom-eye-icon"-->
    <!--            size="16"-->
    <!--            @click="repeatPasswordVisible = !repeatPasswordVisible"-->
    <!--            tabindex="-1"-->
    <!--            >{{ repeatPasswordVisible ? "mdi-eye" : "mdi-eye-off" }}</v-icon-->
    <!--          >-->
    <!--        </template>-->
    <!--      </v-text-field>-->
    <!--    </div>-->
    <div class="list__wrapper">
      <p class="validation__description">Password Must Contain:</p>
      <ul class="list listValidatePassword d-flex flex-wrap flex-column">
        <li
          class="list__item"
          :style="{
            color: '#001154',
          }"
        >
          <v-icon
            :style="{ opacity: password.length >= 8 ? 1 : 0.15 }"
            :color="password.length >= 8 ? '#2969ff' : '#002c91'"
            size="8"
          >
            {{
              password.length >= 8
                ? "mdi-checkbox-marked-circle"
                : "mdi-checkbox-blank-circle"
            }}
          </v-icon>
          8 Characters
        </li>
        <li
          class="list__item"
          :style="{
            color: '#001154',
          }"
        >
          <v-icon
            :style="{ opacity: /[A-Z]/g.test(password) ? 1 : 0.15 }"
            :color="/[A-Z]/g.test(password) ? '#2969ff' : '#002c91'"
            size="8"
          >
            {{
              /[A-Z]/g.test(password)
                ? "mdi-checkbox-marked-circle"
                : "mdi-checkbox-blank-circle"
            }}
          </v-icon>
          An Uppercase
        </li>
        <li
          class="list__item"
          :style="{
            color: '#001154',
          }"
        >
          <v-icon
            :style="{ opacity: /\d/g.test(password) ? 1 : 0.15 }"
            :color="/\d/g.test(password) ? '#2969ff' : '#002c91'"
            size="8"
          >
            {{
              /\d/g.test(password)
                ? "mdi-checkbox-marked-circle"
                : "mdi-checkbox-blank-circle"
            }}
          </v-icon>
          Number
        </li>
        <li
          class="list__item"
          :style="{
            color: '#001154',
          }"
        >
          <v-icon
            :style="{ opacity: /[a-z]/g.test(password) ? 1 : 0.15 }"
            :color="/[a-z]/g.test(password) ? '#2969ff' : '#002c91'"
            size="8"
          >
            {{
              /[a-z]/g.test(password)
                ? "mdi-checkbox-marked-circle"
                : "mdi-checkbox-blank-circle"
            }}
          </v-icon>
          A Lowercase
        </li>
        <li
          class="list__item"
          :style="{
            color: '#001154',
          }"
        >
          <v-icon
            :style="{ opacity: /\W|_/g.test(password) ? 1 : 0.15 }"
            :color="/\W|_/g.test(password) ? '#2969ff' : '#002c91'"
            size="8"
          >
            {{
              /\W|_/g.test(password)
                ? "mdi-checkbox-marked-circle"
                : "mdi-checkbox-blank-circle"
            }}
          </v-icon>
          A Special Character
        </li>
      </ul>
    </div>

    <v-btn
      :color="$vuetify.theme.themes.light.accent"
      :loading="$store.state.ui.buttonLoading"
      @click="submit"
      width="100%"
      class="submit-btn mt-6"
      :disabled="!isAllValid"
      height="40px"
      >Create Account</v-btn
    >

    <ZentinelLogo />
  </v-form>
</template>

<script>
import { Auth } from "aws-amplify";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import handleLogsMixin from "@/mixins/handleLogsMixin";
import ZentinelLogo from "@/components/layout/ZentinelLogo";
import { messageType, OutgoingMessage } from "@/plugins/zentinel";
import ActiveCampaing from "@/services/apiCalls/activeCampaing/activeCampaign";
import Clabel from "@/components/Common/CLabel.vue";
import user from "@/services/apiCalls/user/user.js";
import validateEmail from "@/utils/validations/email";
import { mapActions } from "vuex";
export default {
  name: "RegisterForm",

  mixins: [validationMixin, handleLogsMixin],

  components: {
    ZentinelLogo,
    "c-label": Clabel,
  },

  props: {
    registrationText: {
      type: String,
      required,
    },
    initialEmail: {
      type: String,
      default: null,
    },
    formTitle: {
      type: String,
      default: "",
    },
    onloginButtonClick: {
      type: Object,
      default: () => null,
    },
    afterResendCodeDialogClose: {
      type: Object,
      default: () => null,
    },
    afterRegister: {
      type: [Function, Object],
      default: null,
    },
  },

  data() {
    return {
      inputColor: "#3ba9e0",
      passwordVisible: false,
      repeatPasswordVisible: false,
      password: "",
      repeatEmail: "",
      repeatPassword: "",
      email: "",
      error: "",
      termsChecked: false,
    };
  },

  watch: {
    email(newValue) {
      this.email = newValue ? newValue.toLowerCase() : newValue;
    },
    repeatEmail(newValue) {
      this.repeatEmail = newValue ? newValue.toLowerCase() : newValue;
    },
    initialEmail: {
      handler(newValue) {
        this.email = newValue;
      },
      immediate: true,
    },
  },

  validations: {
    email: { required, email, validateEmail },
    password: { required, minLength: minLength(8) },
    // repeatEmail: { required, sameAs: sameAs("email") },
    // repeatPassword: { required, sameAs: sameAs("password") },
  },

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password must be 8 characters long");
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.required && errors.push("Password is required");
      !this.$v.repeatPassword.sameAs &&
        errors.push("Repeat Password must be the same");
      return errors;
    },
    repeatEmailErrors() {
      const errors = [];
      if (!this.$v.repeatEmail.$dirty) return errors;
      !this.$v.repeatEmail.required && errors.push("Email is required");
      !this.$v.repeatEmail.sameAs && errors.push("Email address doesn’t match");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.validateEmail && errors.push("Wrong symbols in email");
      return errors;
    },
    validation() {
      return {
        uppercase: /[A-Z]/g.test(this.password),
        lowercase: /[a-z]/g.test(this.password),
        number: /\d/g.test(this.password),
        specialChar: /\W|_/g.test(this.password),
        isLength: this.password.length >= 8,
      };
    },
    isAllValid() {
      const isPasswordValid =
        this.validation.uppercase &&
        this.validation.lowercase &&
        this.validation.number &&
        this.validation.specialChar &&
        this.validation.isLength;

      return (
        isPasswordValid &&
        this.passwordErrors.length === 0 &&
        this.emailErrors.length === 0
      );
      // this.repeatEmailErrors.length === 0 &&
      // this.repeatPasswordErrors.length === 0 &&
      // this.repeatEmail &&
      // this.repeatPassword
    },
  },

  methods: {
    ...mapActions("AuthHelpers", ["signUp", "signIn"]),
    saveUtmParams() {
      let utmLocalParams = JSON.parse(localStorage.getItem("utmParams"));
      const { utm_source, utm_medium, utm_campaign } = this.$route.query;

      if (!utmLocalParams && (utm_source || utm_medium || utm_campaign)) {
        utmLocalParams = {
          utmSource: utm_source,
          utmMedium: utm_medium,
          utmCampaign: utm_campaign,
        };
      }
      localStorage.setItem("utmParams", JSON.stringify(utmLocalParams));

      if (!utmLocalParams) return;
      const utmObjParams = {};
      for (const prop in utmLocalParams) {
        if (utmLocalParams[prop]) {
          utmObjParams[prop] = utmLocalParams[prop];
        }
      }
      this.$store.dispatch("Helpers/saveUTMParameters", {
        utmParameters: utmObjParams,
      });
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.$store.commit("setButtonLoad", true);
      try {
        let data;

        try {
          data = await this.signUp({
            email: this.email,
            password: this.password,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("err", err);
          this.$root.alert({
            title: "Email verification",
            content: err.message,
          });
          this.$store.commit("setButtonLoad", false);
          this.handleLogs({
            errorInfo: JSON.stringify(err),
            errorId: "Registering User",
          });
        }

        if (!data) {
          return false;
        }

        try {
          this.$zentinel.send(
            new OutgoingMessage(messageType.REGISTER_USER, {
              email: this.email,
              password: this.password,
            })
          );
          await this.$zentinel.listenForOne(
            messageType.REGISTER_USER,
            1000,
            1000
          );
        } catch (err) {
          this.$store.commit("setButtonLoad", false);

          this.handleLogs({
            errorInfo: JSON.stringify(err),
            errorId: "Registering User",
          });
        }

        if (data?.UserConfirmed === true) {
          //HERE
          await this.signInFlow(this.email, this.password);
          await this.$store.dispatch("logIn");
          this.$store.commit("setButtonLoad", false);
          this.$eventHub.$emit("successfulRegistration");
          this.$root.closeRegisterDialog();
          this.password = "";
          this.repeatPassword = "";
          this.email = "";
          this.saveUtmParams();
          setTimeout(() => {
            try {
              this.$root.validateUserProfile();
            } catch (e) {
              console.log(e);
            }
          }, 300);
        }
      } catch (err) {
        this.error = err && err.message;
        this.$store.commit("setButtonLoad", false);
        this.$root.alert({
          title: "Email verification",
          content: err.message,
        });
        this.handleLogs({
          errorInfo: JSON.stringify(err),
          errorId: "Registering User",
        });
      }
      this.refreshChangeRoleSubscription();
      if (this.afterRegister) {
        this.afterRegister();
      }
    },
    openLoginDialog() {
      this.$root.closeRegisterDialog();
      this.$root.openLoginDialog({ email: this.email });
    },
    refreshChangeRoleSubscription() {
      this.$store.commit("unsubscribeRoleChange");
      this.$emit("handleRoleChangeSubscription");
    },

    async signInFlow(username, password) {
      try {
        const user = await this.signIn({ username, password });
        const isChallengeNameRequired =
          user && user.challengeName === "NEW_PASSWORD_REQUIRED";

        if (user && isChallengeNameRequired) {
          Auth.completeNewPassword(user, password, {
            email: username,
          })
            .then(() => {
              this.$store.dispatch("logIn");
            })
            .catch((e) => {
              console.error(e);
              this.error = e;
            });
        }

        if (user) {
          this.$store.dispatch("logIn");
        }
      } catch (err) {
        this.$store.commit("openSnackBar", {
          text: err.message,
          color: "error",
        });
        this.handleLogs({
          errorInfo: JSON.stringify(err),
          errorId: "Logging in User",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.listValidatePassword {
  color: map-get($colors, "primary");
  font-size: 11px;
  list-style: none;
  margin: 0 0 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.list {
  margin-left: 8px;
  margin-bottom: 14px;
  padding-right: 10px;
  &__wrapper {
    background-color: rgba(41, 105, 255, 0.1);
  }
  &__item {
    margin-bottom: -2px;
    margin-right: -10px;
    font-size: 10px;
    color: rgba(0, 17, 84, 0.7);
  }
}
.validation__description {
  margin-left: 9px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #001154;
  letter-spacing: 0;
}
.text-check {
  align-items: baseline;
  font-size: 9px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #001154;
  margin-top: 2px !important;
  margin-left: 24px;
}
.custom-eye-icon {
  padding-top: 2px;
  margin-right: 10px !important;
}
::v-deep {
  .list__item {
    i {
      font-size: 12px !important;
    }
    font-size: 12px;
  }
  .list__wrapper {
    padding-top: 8px;
    height: 73px;
    display: flex;
    flex-direction: column;
  }
  .second-input-with-background .v-input {
    margin-bottom: 8px !important;
  }
  .v-btn__content {
    font-size: 16px !important;
  }
  .second-input-with-background {
    font-size: 14px !important;
  }
}
.cardPopUp__text {
  .v-btn {
    margin-bottom: 16px !important;
  }
}
</style>
