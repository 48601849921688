import { openDB } from "idb";

class DBInterface {
  constructor(databaseName, version, stores) {
    this.databaseName = databaseName;
    this.version = version;
    this.stores = stores;
  }

  async init() {
    await this.openDatabase();
  }
  async openDatabase() {
    this.db = await openDB(this.databaseName, this.version, {
      upgrade: (db) => {
        // eslint-disable-next-line no-console
        console.log("VERSION UPGRADE");
        for (const storeName of this.stores) {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        }
      },
      blocked: () => {
        if (this.db) {
          this.db.close();
        }
      },
      blocking: () => {
        if (this.db) {
          this.db.close();
        }
      },
    });
  }

  async get(storeName, key) {
    await this.openDatabase();
    const tx = this.db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const res = await store.get(key);
    this.db.close();

    return res;
  }

  async put(storeName, value) {
    await this.openDatabase();
    const tx = this.db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.put(value);
    await tx.done;
    this.db.close();
  }

  async getAll(storeName) {
    await this.openDatabase();
    const tx = this.db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const res = await store.getAll();
    this.db.close();

    return res;
  }

  async clear(storeName) {
    await this.openDatabase();

    const tx = this.db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.clear();
    await tx.done;
    this.db.close();
  }

  async delete(storeName, key) {
    await this.openDatabase();

    const tx = this.db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.delete(key);
    await tx.done;
    this.db.close();
  }
}

export default DBInterface;
