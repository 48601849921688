import { editAssetGuard } from "@/routerGuards/guardEditAsset";

export default [
  {
    path: "/asset/create",
    redirect: "/sell-your-loan/create",
  },
  {
    path: "/sell-your-loan/create",
    name: "AssetCreatePage",
    component: () => import("./pages/Asset/Create"),
    meta: {
      // requiresAuth: true,
      hideFooter: true,
      title: "Asset Create - Xchange.Loans",
      makeAssetFlow: true,
    },
  },
  {
    path: "/sell-your-loan",
    name: "AssetEditPage",
    component: () => import("./pages/Asset/Edit"),
    beforeEnter: editAssetGuard,
    meta: {
      // requiresAuth: true,
      hideFooter: true,
      title: "Asset Create - Xchange.Loans",
      makeAssetFlow: true,
    },
    children: [
      {
        path: "asset-info/:id",
        name: "AssetInfo",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () =>
          import("./components/AssetCommon/AssetFlow/AssetInfo.vue"),
      },
      {
        path: "note-overview/:id",
        name: "NoteOverview",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () =>
          import("./components/AssetCommon/AssetFlow/NoteOverview.vue"),
      },
      {
        path: "property-details/:id",
        name: "PropertyDetails",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () =>
          import("./components/AssetCommon/AssetFlow/PropertyDetails.vue"),
      },
      {
        path: "pictures/:id",
        name: "Pictures",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () =>
          import("./components/AssetCommon/AssetFlow/Pictures.vue"),
      },
      {
        path: "due-diligence/:id",
        name: "DueDiligence",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () =>
          import("./components/AssetCommon/AssetFlow/DueDiligence.vue"),
      },
      {
        path: "legal/",
        name: "Legal",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () => import("./components/AssetCommon/AssetFlow/Legal.vue"),
        children: [
          {
            path: "ca/:id",
            name: "LegalCa",
            meta: {
              hideFooter: true,
              makeAssetFlow: true,
            },
            component: () =>
              import("./components/AssetCommon/AssetFlow/LegalCA.vue"),
          },
          // {
          //   path: "lpa/:id",
          //   name: "lpa",
          //   meta: {
          //     hideFooter: true,
          //   },
          //   component: () =>
          //     import("./components/AssetCommon/AssetFlow/LegalLPA.vue"),
          // },
          {
            path: "bda/:id",
            name: "bda",
            meta: {
              hideFooter: true,
              makeAssetFlow: true,
            },
            component: () =>
              import("./components/AssetCommon/AssetFlow/LegalBDA.vue"),
          },
          {
            path: "terms/:id",
            name: "LegalTerms",
            meta: {
              hideFooter: true,
              makeAssetFlow: true,
            },
            component: () =>
              import("./components/AssetCommon/AssetFlow/LegalTaC.vue"),
          },
        ],
      },
      {
        path: "advisors/:id",
        name: "Advisors",
        meta: {
          hideFooter: true,
          makeAssetFlow: true,
        },
        component: () =>
          import("./components/AssetCommon/AssetFlow/Advisors.vue"),
      },
    ],
  },
  {
    path: "/asset/:id/edit",
    name: "AssetEditPage",
    component: () => import("./pages/Asset/Edit"),
    meta: {
      requiresAuth: true,
      hideFooter: true,
      title: "Asset Edit - Xchange.Loans",
      editAssetFlow: true,
    },
  },
];
