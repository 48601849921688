import { API } from "aws-amplify";
export default (id) => {
  const isAuth = localStorage.getItem("authenticated");

  const query = `query getAssetById{
    getAsset(id: "${id}") {
          id,
          currentStep,
          registrationStatus,
          assetStatus,
          tier_1_whitelist
          tier_2_whitelist
          caConfirmed
          TOCConfirmed
          BDAConfirmed
          customCADoc
          contactsDisclosed
          payment {
            status
          }
          foreclosure{
            foreclosureProcess
            receivershipProcess
            biddingProcess
          },
          name,
          description,
          dateModified,
          status,
          type,
          upbDate,
          images,
            principalBalance {
            amount
            currency
          }
            listingPrice {
            amount
            currency
          }
            requireBidDeposit,
            bidDepositSum {
            amount
            currency
          }
            preferredBidDepositChannel,
            displayDueDate
            callForOffersPeriod {
            startDate
            endDate
          }
            bestAndFinalPeriod {
            startDate
            endDate
          }
          noteOverview {
            numberOfLoans
              originationDate
              maturityDate
              loanAmount {
                amount
              }
              unpaidPrincipalBalance {
              amount
              currency
            }
            totalPayoff {
              amount
              currency
            }
            estCollateralValue {
              amount
              currency
            }
              initialInterestType
              initialInterestRate
              currentInterestRate
              defaultInterestRate
              term
              amortization
              recourse
              modified
              assumptionOfLoanAllowed
              prePaymentLimitations
              penalty
              monetaryDefault
              maturityDefault
              technicalDefault
          }
            offers {
              id
            }
            owner
            ownerEmail
            owners
            propertyOverview {
              propertyType
              subtype
              classField
              yearBuilt
              yearRenovated
              size
              apn
              zoning
              nraTotal
              unitOfMeasure
              constructionType
              buildingLayout
              units
              stories
              buildings
              condo
              multiProperty
              parkingType
              parkingSpaces
              environmentalIssues
              propertyCondition
              feeLeasehold
              largestTenantCredit
              tenantType
              isOwnerOccupied
              ownerOcc
              class
              propertyTaxesCurrent
          }
            address {
            country
              state
              city
              address
              zipCode
          }
            contacts {
              firstName
              lastName
              officePhone
              emailAddress
              companyName
              info {
                avatar
                jobTitle
              }
              whatsappNumber
              contactAvatar
            }
            tier1_id
            tier2_id
            pricingPlan
            tier_home_id
            
      }
    }
  `;
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
  }
  let additionalHeaders = {
    // eslint-disable-next-line quotes, prettier/prettier
    'user_id': currentUser?.sub,
  };
  additionalHeaders = currentUser ? additionalHeaders : null;

  return API.graphql(
    {
      query,
      variables: { id },
      authMode: isAuth ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
    },

    additionalHeaders
  );
};
