import store from "../store";

async function closingGuard(to, from, next) {
  const { role, id } = to.params;

  await store.dispatch("OfferFlow/fetchOffer", id);
  const offer = store.state.OfferFlow.offer;

  if (!store.getters.user) {
    next();
  } else if (!offer) {
    next("/");
  } else if (role === "buyer" && store.getters.isBuyer) {
    if (offer && offer.owners[0] === store.getters.user.id) {
      if (offer.buyerClosingPeriod && !offer.buyerClosingDocumentsAccepted) {
        next();
      } else {
        next("/dashboard/buyer");
      }
    } else {
      next("/");
    }
  } else if (role === "seller" && store.getters.isSeller) {
    if (offer && offer.assetsDetailed[0].owners[0] === store.getters.user.id) {
      if (offer.sellerClosingPeriod && !offer.sellerClosingDocumentsAccepted) {
        next();
      } else {
        next("/seller/filters/active");
      }
    } else {
      next("/");
    }
    next();
  }
}

function buyerClosingGuard(to, from, next) {
  const { role, id } = to.params;
  if (store.getters.isBuyer && role === "buyer") {
    const closingOffer = store.state.buyerDashboard.offerItems.find(
      (offer) => offer.id === id
    );

    if (
      closingOffer &&
      closingOffer.buyerClosingPeriod &&
      !closingOffer.buyerClosingDocumentsAccepted
    ) {
      next();
    } else {
      next("/dashboard/buyer");
    }
  } else {
    next("/");
  }
}

function sellerClosingGuard(to, from, next) {
  const { role, id } = to.params;
  if (store.getters.isSeller && role === "seller") {
    const closingAsset = store.state.UserAssets.userAssets.find(
      (asset) => asset.winnerOfferId === id
    );

    if (
      closingAsset &&
      closingAsset.sellerClosingPeriod &&
      !closingAsset.sellerClosingDocumentsAccepted
    ) {
      next();
    } else {
      next("/seller/filters/active");
    }
  } else {
    next("/");
  }
}

export { closingGuard, buyerClosingGuard, sellerClosingGuard };
