var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({staticClass:"asset-status second-select",class:_vm.$attrs.errorClass,attrs:{"chips":"","menu-props":{
    'offset-y': true,
    bottom: _vm.bottom,
    top: _vm.top,
    allowOverflow: true,
    'content-class': _vm.hideMenu ? 'd-none' : _vm.contentMenuCustomMenu,
  },"item-color":"#2969FF","multiple":!_vm.single,"rules":_vm.rules,"attach":_vm.attach},on:{"change":function($event){return _vm.$emit("change")}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [(_vm.short && !_vm.isMobile)?[(index === 0)?_c('v-chip',{attrs:{"color":"white","close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.deleteChip(_vm.$attrs.value)}}},[_vm._v(" "+_vm._s(_vm.getValuesCount(item, _vm.$attrs))+" ")]):_vm._e()]:[(index === 0 && !_vm.isMobile)?_c('v-chip',{attrs:{"color":"white","close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.deleteChip(_vm.$attrs.value)}}},[_c('span',{staticClass:"chip-text"},[_vm._v(" "+_vm._s(item.name.length > 12 && _vm.slicedValue ? item.name.slice(0, 12) + "..." : item.name)+" ")])]):_vm._e(),(!_vm.isMobile && index === 1)?_c('v-chip',{attrs:{"color":"white","close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.deleteHiddenChips(_vm.$attrs.value)}}},[_vm._v(" + "+_vm._s(_vm.$attrs.value.length - 1)+" ")]):_vm._e(),(_vm.isMobile && index === 0)?_c('v-chip',{attrs:{"color":"white","close-icon":"mdi-close","close":""},on:{"click:close":function($event){return _vm.deleteHiddenChips(_vm.$attrs.value)}}},[_vm._v(" + "+_vm._s(_vm.$attrs.value.length)+" ")]):_vm._e()]]}},(_vm.$vuetify.breakpoint.xs && _vm.itemsCount > 0)?{key:"append",fn:function(){return [_c('div',{staticClass:"items-count-wrapper"},[_vm._v(" "+_vm._s(_vm.itemsCount)+" ")])]},proxy:true}:{key:"append",fn:function(){return [(_vm.customAppend)?_c('v-list-item-action',{staticClass:"custom-icon"},[_c('img',{attrs:{"src":_vm.selectIcon}})]):_vm._e()]},proxy:true}],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }