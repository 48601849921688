<template>
  <v-row
    :class="{ 'new-msg-line': getNewMsgCount }"
    class="chat-list-row no-gutters text-left px-3 py-3"
    @click="selectChat()"
  >
    <v-col cols="10" class="cursor-pointer info-column">
      <div class="line-wrapper">
        <div class="avatar-wrapper">
          <ChatAssetImg
            v-if="label === 'buyer'"
            :user="getChat.owner"
            :assetImage="getChat.assetInfo.assetImage"
          >
          </ChatAssetImg>
          <ChatAvatar
            v-else
            :user="getChat.buyerInfo"
            :avatar="getChat.owner.avatar"
            :color="getColor"
          ></ChatAvatar>
        </div>
        <div class="info-wrapper pl-3">
          <div v-if="getChat.assetInfo" class="chat-title">
            {{ getChat.assetInfo.name }} -
            {{ getChat.linkStatus }}
          </div>
          <div v-if="label === 'seller'" class="buyer-line">
            <span class="buyer-line-buyer"> Buyer: </span>
            {{ getBuyer }}
          </div>
          <div class="last-msg-line">
            <div class="owner"></div>
            <div v-if="getLastMsg" class="last-msg">
              <span class="owner">
                {{ getLastMsgOwnerName }}: {{ getLastMsg }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col cols="2" class="d-flex justify-end action-column">
      <ConversationChatListNewMsg
        :counter="getNewMsgCount"
      ></ConversationChatListNewMsg>
    </v-col>
  </v-row>
</template>

<script>
import ChatAvatar from "@/components/Chat/ChatAvatar.vue";
import ChatAssetImg from "@/components/Chat/ChatAssetImg.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import ConversationChatListNewMsg from "@/components/Chat/ConversationChatListNewMsg.vue";

export default {
  name: "ConversationChatListLine",
  components: { ConversationChatListNewMsg, ChatAvatar, ChatAssetImg },
  props: {
    chat: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("Chat", ["selectedConversation", "sellerChats", "buyerChats"]),
    getBuyer() {
      return `${this.getChat.buyerInfo.firstName} ${this.getChat.buyerInfo.lastName}`;
    },
    getColor() {
      if (this.label === "seller") {
        return this.getChat.buyerInfo.color;
      }
      return this.getChat.sellerInfo.color;
    },
    userId() {
      return this.$store.state.user.id;
    },
    getNewMsgCount() {
      if (this.chat.twilioSID === this.selectedConversation?.twilioSID) {
        return 0;
      }

      const lastMessage = this.chat.TWILIO_LastMessage;
      const lastReadMessageIndex = this.chat.TWILIO_lastReadMessageIndex;
      if (
        Number.isInteger(lastMessage?.index) &&
        lastReadMessageIndex === null
      ) {
        return lastMessage?.index + 1;
      }
      if (lastMessage && Number.isInteger(lastReadMessageIndex)) {
        return lastMessage?.index - lastReadMessageIndex;
      }

      return 0;
    },
    getLastMsg() {
      return this.chat.msgText || this.chat.attributes?.lastMessage;
    },
    getLastMsgOwnerName() {
      if (this.getChat.senderID) {
        if (this.getChat.sellerID === this.getChat.senderID) {
          return this.getChat.sellerInfo.firstName
            ? `${this.getChat.sellerInfo.firstName} ${this.getChat.sellerInfo.lastName}`
            : "Asset Seller";
        } else {
          return this.getChat.buyerInfo.firstName
            ? `${this.getChat.buyerInfo.firstName} ${this.getChat.buyerInfo.lastName}`
            : this.getChat.buyerInfo.email;
        }
      } else if (this.getChat.owner) {
        if (this.getChat.owner.anonymous) {
          return "Asset Seller";
        }
        return this.getChat.owner.firstName
          ? `${this.getChat.owner.firstName} ${this.getChat.owner.lastName}`
          : this.getChat.owner.email;
      }
      return "";
    },
    getChat() {
      if (this.chat.twilioSID) {
        const {
          assetInfo,
          attributes,
          buyerInfo,
          sellerInfo,
          sellerID,
          buyerID,
        } = this.chat ?? {};
        const { assetStatus } = assetInfo ?? {};
        const { lastMessage, lastMessageOwnerId } = attributes ?? {};
        const linkStatus = assetStatus
          .reduce((acc, item) => acc + item, "")
          .replace(/ /g, "-")
          .toLowerCase();

        const { sellerProfile } = buyerInfo ?? {};
        const { avatar } = sellerProfile ?? {};
        let owner = {
          avatar,
        };

        if (lastMessageOwnerId === sellerID) {
          owner = {
            ...owner,
            ...sellerInfo,
          };
        } else {
          owner = {
            ...owner,
            ...buyerInfo,
          };
        }

        const info = {
          ...this.chat,
          owner,
          linkStatus,
          lastMessage,
          lastMessageOwnerId,
        };

        return info;
      }
      return { owner: {} };
    },
  },
  methods: {
    ...mapActions("Chat", ["getChatInfo"]),
    ...mapMutations("Chat", [
      "setSelectedChat",
      "setSelectedChatType",
      "setSelectedChatAsset",
      "setSelectedConversation",
      "setFUserId",
      "setSUserId",
      "setUniqName",
      "setSellerChats",
      "setBuyerChats",
    ]),
    selectChat() {
      if (this.selectedConversation?.twilioSID === this.chat.twilioSID) return;
      const { assetID, buyerID, sellerID, assetInfo: asset } = this.chat;
      const uniqName = `${assetID}#${sellerID}#${buyerID}`;

      this.setSelectedChatType(this.label);
      this.setSelectedConversation(this.chat);
      this.setSelectedChatAsset(asset);
      this.setFUserId(sellerID);
      this.setSUserId(buyerID);
      this.setUniqName(uniqName);

      this.setAllMessagesRead();
    },
    setAllMessagesRead() {
      const readConversation = {
        ...this.selectedConversation,
        TWILIO_lastReadMessageIndex:
          this.selectedConversation.TWILIO_LastMessage.index,
      };
      if (this.label === "seller") {
        this.updateChatList(
          this.sellerChats,
          this.setSellerChats,
          readConversation
        );
      } else {
        this.updateChatList(
          this.buyerChats,
          this.setBuyerChats,
          readConversation
        );
      }
    },
    updateChatList(chats, setterFunction, readConversation) {
      const newList = chats.map((chat) => {
        if (chat.twilioSID === this.selectedConversation.twilioSID) {
          return {
            ...chat,
            ...readConversation,
          };
        }
        return { ...chat };
      });
      setterFunction(newList);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-list-row {
  height: 80px;
  border-top: 1px solid #979797;
  position: relative;
  &.new-msg-line {
    background-color: #f2f6ff;
  }

  .info-column {
    flex: 1;

    .line-wrapper {
      display: flex;
    }
  }
}
.action-column {
  width: 30px;
}
.menu-btn {
  background: none;
}
.edit-menu {
  border-radius: 6.659px !important;
  background: #fff;
  box-shadow: 3.995px 5.327px 19.977px 0px rgba(0, 17, 84, 0.14) !important;

  .header-item {
    color: #001154;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    > div {
      padding-bottom: 4px;
      border-bottom: 1px solid #9aabc2;
    }
  }
  .menu-item {
    min-height: auto;
  }
}
.avatar-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  ::v-deep {
    .avatar img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .rounded-username {
      position: relative;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      //margin-right: 16px;
      border-radius: 50%;
      box-shadow: 0px 8px 25px 0px rgba(0, 17, 84, 0.25);

      .active-pointer {
        position: absolute;
        width: 14px;
        height: 14px;
        background: #368600;
        border-radius: 50%;
        border: 1px solid white;
        bottom: 0px;
        right: -3px;
      }
    }
  }
}
.info-wrapper {
  flex: 1;

  .buyer-line {
    color: #001154;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    .buyer-line-buyer {
      font-weight: 500;
      line-height: 20px;
    }
  }

  .last-msg-line {
    color: #001154;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.7;
  }

  .chat-title {
    color: #001154;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    width: 310px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .last-msg {
    .owner {
      max-width: 285px;
      font-weight: 500;
      line-height: 20px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      opacity: 0.7;
      font-size: 14px;
      color: #001154;
    }
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .chat-list-row {
    height: 85px;
  }
  .chat-title {
    width: auto !important;
    max-width: 285px;
  }
}

@media screen and (max-width: 485px) {
  .chat-title {
    max-width: 170px;
  }
  .last-msg .owner {
    max-width: 170px !important;
  }
}
</style>
